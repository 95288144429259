 
import './servicios_parques.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';
import { graphql } from 'gatsby';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { generatedBreadCrumb } from '../src/helpers/breadCrumbs';
import Ymir from '../src/components/Ymir/Ymir';
import tt from '../src/helpers/translation';

class ServiciosParque extends Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allServiciosTitleHeader.edges[0].node.title,
      image: {
        url: this.props.data.allServiciosImageHeader.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const ymirData = {
      cards: [
        {
          title: tt('HORARIOS Y CALENDARIO', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/horarios-calendario', this.props.pageContext.locale),
          icon: 'calendar',
        },
        {
          title: tt('CÓMO LLEGAR AL RESORT', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/destino/planificar/como-llegar-a-portaventura', this.props.pageContext.locale),
          icon: 'LOCATION',
        },
        {
          title: tt('DESCUENTOS EN ENTRADAS ONLINE', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/promociones', this.props.pageContext.locale),
          icon: 'Methodofpayment',
        },
      ],
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allServicesSeoData.edges[0].node._0.title}
          description={this.props.data.allServicesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="servicios-parques-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allParkServicesBreadCrumbBlock.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allParkServicesRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allServiciosImageHeader.edges[0].node.localImage.childImageSharp
                    .fluid
                }
              />
              <BlueArrow />
              <H3 data={this.props.data.allParkServicesMainTextBlock.edges[0].node} />
              <div
                className="inner-text"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allParkServicesMainTextBlock.edges[0].node.body,
                }}
              />
              <Ymir data={ymirData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
ServiciosParque.propTypes = {
  ourShopsData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default ServiciosParque;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query serviciosPortAventuraQuery($locale: String!) {
    allServiciosTitleHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allServiciosImageHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allServicesSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allParkServicesBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          destination_url
        }
      }
    }
    allParkServicesMainTextBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
        }
      }
    }
    allParkServicesRichData {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;
